<template>
  <div class="index">
    <headers />
    <navs />
    <div class="el_content">
      <router-view />
    </div>

    <footers />
  </div>
</template>

<script>
import headers from '@/components/header'
import navs from '@/components/nav'
import footers from '@/components/footers'
export default {
  data () {
    return {
      msg: '这是首页',
    }
  },
  components: {
    headers,
    navs,
    footers
  },
  created () {

  },
  methods: {
    // toTaglet(){

    // },
  }
}
</script>
<style scoped lang="scss">
.index {
  width: 100%;
  height: 100%;
  .el_content {
    min-height: 500px;
  }
}
</style>
