<template>
  <div class="cli_footers">
    <div class="footer">
      <div class="wrapper">
        <div class="footer-connect">
          <div class="footer-qr">
            <img src="../assets/images/qr.png" />
          </div>
          <ul class="footer-info">
            <li>
              <img src="../assets/images/icon-zsrx.png" />招商热线：<strong class="fc-white">0311-66571206</strong>
            </li>
            <li>
              <img src="../assets/images/icon-kfrx.png" />客服热线：<strong class="fc-white">0311-66571208</strong>
            </li>
          </ul>
          <div class="line"></div>
          <ul class="footer-info">
            <li>
              <img src="../assets/images/icon-lxyx.png" />联系邮箱：<strong class="fc-white">sjzrzcyy@163.com</strong>
            </li>
            <li>
              <img src="../assets/images/icon-dizhi.png" />园区地址：<strong class="fc-white">中国河北石家庄高新区裕华东路453号创新大厦</strong>
            </li>
          </ul>
        </div>
        <div class="footer-serve">
          <dl>
            <dt>园区服务</dt>
            <dd v-for="(item,index) in footerList"
                :key="index">
              <a @click="toRouter(item)">
                {{item.title}}
              </a>
            </dd>

          </dl>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="wrapper">©2021 <a href="https://beian.miit.gov.cn"
           target="_blank">冀ICP备19026193号-4</a></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: '这是nfooters',
      footerList: [
        { title: '⼀站式⼤厅', routerName: 'onestopHall' },
        { title: '国际⼈才城', routerName: 'personnel' },
        { title: '供需对接', routerName: 'docking' },
        { title: '⼯作站', routerName: 'workstation' },
        { title: '融媒体', routerName: 'financial' },
      ]
    }
  },
  methods: {
    toRouter (item) {
      this.newRouter('/' + item.routerName)
    }
  }
}
</script>
<style scoped>
</style>
