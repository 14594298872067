<template>
  <div class="header-box">
    <div class="header">
      <div class="logo">
        <a href="#"><img src="../assets/images/logo.png" /></a>
      </div>
      <div class="tels">
        <span><img src="../assets/images/icon-zs.png" />招商热线：0311-66571206</span>
        <span><img src="../assets/images/icon-kf.png" />客服热线：0311-66571208</span>
      </div>
      <div class="gh"
           :class="selectedClass?'selected':''"
           @click="toTaglet">
        <a href="javascript:;"></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selectedClass: false,
    }
  },
  computed: {
    toTagletName () {
      return this.$store.state.toTaglet
    }
  },
  watch: {
    toTagletName (newVal, oldVal) {
      if (!newVal) {
        this.selectedClass = false
      }
    }
  },
  mounted () {
    // this.resize(true)
    if (document.body.offsetWidth > 1201) {
      this.resize(true)
    }
    window.onresize = () => {
      if (document.body.offsetWidth > 1201) {
        this.resize(true)
      }
    }
  },
  methods: {
    toTaglet () {
      this.selectedClass = !this.selectedClass;
      this.$store.commit('setToTaglet', this.selectedClass)
    },
    resize (boolean) {
      this.selectedClass = boolean;
      this.$store.commit('setToTaglet', boolean)
    }
  }
}
</script>
<style scoped>
</style>
