<template>
  <div class="cli_nav">
    <div class="mnav"
         :style="{display:toTaglet?'block':'none'}">
      <ul class="mnav-list">
        <li v-for="(item,index) in list"
            :key="index">
          <a @click="toRouter(item)">
            <img :src="item.imgUrl" />{{item.title}}
          </a>
          <ul class="msubnav">
            <li v-for="(item2,index2) in item.arr"
                :key="index2">
              <a @click="toRouter(item2)">{{item2.title}}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: '这是nav',
      list: [
        {
          router: 'index',
          imgUrl: require('../assets/images/nav01.png'),
          title: '首页'
        },
        {
          router: 'introductionPark',
          imgUrl: require('../assets/images/nav02.png'),
          title: '园区概况',
          arr: [
            { title: '园区简介', router: 'introductionPark' },
            { title: '活动新闻', router: 'activitynews' },
            { title: '公共展示', router: 'exhibition' },
            { title: 'VR园区', router: 'VR' },
          ]
        },
        {
          router: 'onestopHall',
          imgUrl: require('../assets/images/nav03.png'),
          title: '园区服务',
          arr: [
            { title: '一站式大厅', router: 'onestopHall' },
            { title: '国际人才城', router: 'personnel' },
            { title: '供需对接', router: 'docking' },
            { title: '工作站', router: 'workstation' },
            { title: '融媒体', router: 'financial' },
          ]
        },
        {
          router: 'policy',
          imgUrl: require('../assets/images/nav04.png'),
          title: '政策指引',
          arr: [
            { title: '政策资讯', router: 'policy' },
            { title: '招商政策', router: 'investment' },
          ]
        },
        {
          router: 'process',
          imgUrl: require('../assets/images/nav05.png'),
          title: '企业入驻',
          arr: [
            // { title: '入驻流程', router: 'processes' },
            { title: '入驻流程', router: 'process' },
            { title: '入驻企业', router: 'enterprise' },
            { title: '合作企业', router: 'cooperation' },
          ]
        },
        {
          router: 'contact',
          imgUrl: require('../assets/images/nav06.png'),
          title: '联系我们',
          arr: [
            { title: '联系我们', router: 'contact' },
          ]
        },
      ]
    }
  },
  computed: {
    toTaglet () {
      return this.$store.state.toTaglet
    }
  },
  created () {

  },
  methods: {
    toRouter (item) {
      if (item.title == '首页') {
        window.open('http://www.sjzhrsip.com', '_blank')
        return;
      }
      if (item.router == '#') return;
      if (item.router == 'VR') {
        window.open('https://www.kuleiman.com/145674/index.html', '_blank')
        return;
      }
      if (document.body.offsetWidth < 1201) {
        this.$store.commit('setToTaglet', false)
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.$router.push({ name: item.router })
    }
  }
}
</script>
<style scoped>
</style>
